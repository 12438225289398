<template>
  <div class="detail-contaner">
    <Header></Header>
    <Nav></Nav>

    <!-- 商品详情 start -->
    <el-row class="good-box">
      <el-row class="contaner">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'shopping' }"
              >二手机床</el-breadcrumb-item
            >
            <el-breadcrumb-item>商品详情</el-breadcrumb-item> -->
            <!-- <span class="pos">您现在的位置：</span> -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ name: 'home' }">
                <span class="c9">首页</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item :to="{ name: 'shopping' }">
                <span class="c3" style="font-weight: 400">二手机床</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                ><span class="c3">商品详情</span></el-breadcrumb-item
              >
            </el-breadcrumb>
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
        <!-- 详情 start -->
        <el-row class="good-detail-box">
          <el-row class="good-left">
            <img :src="theMainImg" alt="" />
          </el-row>
          <el-row class="good-right">
            <p class="title">{{ goodDetail.goodsName }}</p>
            <p class="price">
              价格<span>
                {{
                  goodDetail.isNegotiable
                    ? "面议"
                    : "¥" + goodDetail.showPrice.toFixed(2)
                }}</span
              >
            </p>
            <el-row class="attr">
              <p class="title">商品属性</p>
              <p class="type">
                设备类型：<span>{{ goodDetail.typeName }}</span>
              </p>
              <p class="type">
                设备品牌：<span>{{ goodDetail.brandName }}</span>
              </p>
              <p class="type">
                设备控制系统：<span class="left">{{
                  goodDetail.controlName
                }}</span>
              </p>
              <p class="type">
                设备型号：<span>{{ goodDetail.deviceModel }}</span>
              </p>
              <p class="type">
                出厂日期：<span>{{ goodDetail.productionDate }}</span>
              </p>
            </el-row>
            <button class="to-buy" @click="toBuy">
              <router-link :to="{ name: 'intention', query: { id: id } }"
                >咨询购买</router-link
              >
            </button>
          </el-row>
          <!-- 商品轮播 start -->
          <el-row
            class="good-lunbo"
            v-if="goodDetail.imgs && goodDetail.imgs.split(',').length"
          >
            <img
              @click="toPrev"
              class="left"
              src="@/assets/images/detail/left.png"
              alt=""
            />
            <div class="show-box" :style="{ width: showBoxHeight + 'px' }">
              <div
                class="img-box"
                :style="{ left: left + 'px', width: imgBoxHeight + 'px' }"
              >
                <!-- goodDetail.imgs -->
                <!-- mainImg -->
                <span
                  v-for="(item, index) in goodDetail.imgs.split(',')"
                  @click="handleMainImg(item)"
                  :key="index"
                >
                  <img :src="item" alt="" />
                </span>

                <!-- <img src="@/assets/images/detail/jichuang2.png" alt="" />
                <img src="@/assets/images/detail/jichuang1.png" alt="" />
                <img src="@/assets/images/detail/jichuang2.png" alt="" />
                <img src="@/assets/images/detail/jichuang1.png" alt="" />
                <img src="@/assets/images/detail/jichuang2.png" alt="" /> -->
              </div>
            </div>
            <img
              class="right"
              @click="toNext"
              src="@/assets/images/detail/right.png"
              alt=""
            />
          </el-row>
          <!-- 商品轮播 end -->
        </el-row>
        <!-- 详情 end -->

        <!-- 商品介绍 start -->
        <el-row class="good-suggest">
          <el-row class="title">
            <p>商品介绍</p>
          </el-row>
          <el-row class="contant">
            <span v-html="goodDetail.content"></span>
          </el-row>
        </el-row>
        <!-- 商品介绍 end -->
      </el-row>
    </el-row>
    <!-- 商品详情 end -->

    <Footer></Footer>
    <!-- 右侧保修 客服 -->
    <el-row class="fix-right">
      <el-row class="item1">
        <el-button type="text" @click="toCollect">
          <img
            src="@/assets/images/shoucang.png"
            v-if="goodDetail.collectionId"
            alt=""
          />
          <img src="@/assets/images/collect-good.png" v-else alt="" />
          <p>收藏</p>
        </el-button>
      </el-row>
      <el-row class="item1">
        <el-button type="text" @click="shareVisible = true">
          <img src="@/assets/images/share-good.png" alt="" />
          <p>分享</p>
        </el-button>
      </el-row>

      <!-- 客服 -->
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
      <!-- 联系客服 -->
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>{{ showConText.officialTel }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>{{ showConText.officialWeChat }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>{{ showConText.officialQq }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>{{ showConText.officialMail }} </span>
        </p>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="shareVisible"
      width="600px"
      :show-close="false"
      class="bassderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">分享</p>
        <img
          src="@/assets/images/home/close.png"
          @click="shareVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">{{ sharePos }}</p>
        <div class="btn-box">
          <button class="abtn" :data-clipboard-text="sharePos" @click="copy">
            复制
          </button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import ClipboardJS from "clipboard";
export default {
  name: "YoufujichuangDetail",
  components: {
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      shareVisible: false, //分享弹窗
      sharePos: "",

      left: 0,
      goodDetail: {},
      id: "", //商品id

      showBoxHeight: 0, //图片盒子宽度
      imgBoxHeight: 0, //所有图片宽度

      showConText: {}, //联系信息

      assderVisible: false, //客服弹窗

      isCollect: false, //是否已收藏

      theMainImg: "",
    };
  },

  mounted() {},
  created() {
    this.id = this.$route.query.id;
    // 获取分享地址
    this.sharePos = location.href;

    // console.log(this.$route.query.id, "参数");
    this.getGoodDetail();

    //根据分组查询系统配置
    this.getConfig();
  },

  methods: {
    // 轮播点击主图变化
    handleMainImg(item) {
      // console.log(item);
      this.theMainImg = item;
    },
    // 分享弹窗复制
    copy() {
      let that = this;
      var clipboard = new ClipboardJS(".abtn");

      clipboard.on("success", function (e) {
        that.$message({
          message: "复制成功",
          type: "success",
          duration: 1000,
        });
        e.clearSelection();
      });

      clipboard.on("error", function (e) {
        that.$message({
          message: "复制失败",
          type: "error",
        });
      });

      setTimeout(() => {
        this.shareVisible = false;
      }, 500);
    },

    // 用户收藏二手商品
    toCollect() {
      if (!this.$store.state.token) {
        this.$message.info("登录后可进行收藏,请登录");
        return;
      }
      if (this.goodDetail.collectionId) {
        // 已经收藏了   取消收藏
        let paramData = {
          ids: Number(this.goodDetail.collectionId),
        };
        this.$API
          .userCollectionDelete(paramData)
          .then((res) => {
            this.isCollect = false;
            if (res.code == 1) {
              this.getGoodDetail();
              this.$message({
                message: "已取消",
                type: "info",
                duration: 1000,
              });
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.isCollect = false;
      } else {
        // 还未收藏  去收藏
        let paramData = {
          collectionType: 1,
          sourceId: Number(this.id),
        };
        this.$API
          .addCollection(paramData)
          .then((res) => {
            if (res.code == 1) {
              this.getGoodDetail();
              this.isCollect = true;
              this.$message({
                message: "收藏成功",
                type: "success",
                duration: 1000,
              });
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "tellUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 去购买
    toBuy() {},
    // 轮播到上一张
    toPrev() {
      if (this.left < 0) {
        this.left = this.left + 98;
      }
    },
    // 轮播到下一张
    toNext() {
      if (this.goodDetail.imgs.split(",").length == 1) {
        return;
      }
      if (!(this.left <= 0)) {
        this.left = this.left - 98;
      }
    },
    // 二手商品-通过id查询
    getGoodDetail() {
      let params = {
        id: this.id,
      };
      this.$API
        .getGoodDetail(params)
        .then((res) => {
          if (res.code == 1) {
            this.goodDetail = res.data;
            this.theMainImg = this.goodDetail.mainImg;
            console.log(this.goodDetail.imgs.split(","));
            this.goodDetail.imgs = res.data.imgs;
            // this.goodDetail.imgs =
            //   "https://xiaoheitu.oss-cn-beijing.aliyuncs.com/companyDynamic/2022/08/1011336358024843264.png";
            // https://xiaoheitu.oss-cn-beijing.aliyuncs.com/companyDynamic/2022/08/1011336094903570432.jpg
            this.showBoxHeight = this.goodDetail.imgs.split(",") * 88;
            if (this.goodDetail.imgs.split(",").length >= 5) {
              this.showBoxHeight = 490;
            } else {
              if (this.goodDetail.imgs.split(",").length == 1) {
                this.showBoxHeight = 88;
              } else {
                this.showBoxHeight =
                  this.goodDetail.imgs.split(",").length * 98;
              }
            }

            this.imgBoxHeight = this.goodDetail.imgs.split(",").length * 88;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-contaner {
  // 右侧保修 客服
  .fix-right {
    cursor: pointer;
    width: 88px;
    height: 180px;
    border: 1px solid #ebebeb;
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .item1 {
      background-color: #ffffff;
      border: 1px solid #ebebeb;
    }
    .item2 {
      margin-top: 57px;
      background-color: #ed1b23;
    }
    .item1,
    .item2 {
      height: 90px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 9px;
      img {
        width: 24px;
        height: 24px;
      }
      p {
        color: #757575;
        font-size: 14px;
        margin-top: 12px;
      }
    }
    .item2 p {
      color: #ffffff;
    }
  }
  // 自定义弹窗样式
  .assderTc {
    display: flex;
    align-items: center;
    .title {
      padding-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 32px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      height: 311px;
      box-sizing: border-box;
      padding-left: 214px;
      padding-top: 52px;
      p {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 14px;
          color: #333333;
          margin-left: 16px;
        }
      }
    }
  }
  .good-box {
    min-width: 1200px;
    width: 100%;
    padding-bottom: 40px;
    border-top: 1px solid #ed1b23;
    background: #f8f8f8;
    .contaner {
      background: #f8f8f8;
    }
  }
  .nav-box {
    padding-top: 36px;
    .pos {
      color: #333333;
      font-size: 16px;
    }
  }
  // 详情
  .good-detail-box {
    background-color: #ffffff;
    margin-top: 24px;
    .good-left {
      display: inline-block;
      width: 520px;
      // height: 450px;
      margin-right: 32px;
      background: #ffffff;
      border: 1px dotted #dcdcdc;
      img {
        width: 520px;
        height: 450px;
      }
    }
    .good-right {
      width: 648px;
      display: inline-block;
      .title {
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        @include dot(1);
      }
      .price {
        margin-top: 20px;
        width: 648px;
        height: 64px;
        line-height: 64px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fff0f1;
        font-size: 14px;
        color: #333333;
        span {
          font-size: 20px;
          font-weight: 800;
          color: #ff0015;
          margin-left: 10px;
          position: relative;
          top: 2px;
        }
      }
      .attr {
        box-sizing: border-box;
        padding: 20px;
        width: 648px;
        height: 244px;
        background: #fafafa;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .type {
          margin-top: 10px;
          font-size: 14px;
          color: #333333;
          span {
            color: #666666;
            margin-left: 60px;
          }
          span.left {
            margin-left: 32px;
          }
        }
      }
    }
    .to-buy {
      margin-top: 10px;
      width: 269px;
      height: 42px;
      background: linear-gradient(100deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      font-size: 16px;
      color: #ffffff;
    }
  }
  // 商品轮播
  .good-lunbo {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    height: 128px;
    width: 522px;
    .left,
    .right {
      width: 16px;
      height: 16px;
      position: relative;
      top: -36px;
    }
    .show-box {
      display: inline-block;
      width: 480px;
      height: 88px;
      position: relative;
      overflow: hidden;
      .img-box {
        transition: left 0.3s;
        width: 578px;
        height: 88px;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: space-between;
        span {
          margin-right: 10px;
        }
        img {
          width: 88px;
          height: 88px;
        }
      }
    }
  }
  // 商品介绍
  .good-suggest {
    margin-top: 40px;
    .title {
      height: 50px;
      background: #fafafa;
      border-radius: 0px 0px 0px 0px;
      border: 1px solid #eeeeee;
      border-bottom: 2px solid #ed1b23;
      p {
        width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #ed1b23;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .contant {
      margin: 0 auto;
      margin-top: 46px;
      // width: 1000px;
      // height: 4320px;
      // background: #ffffff;
      // border: 1px solid #707070;
      img {
        width: 1000px;
        height: 4320px;
      }
    }
  }
  // 自定义弹窗样式
  .bassderTc {
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    display: flex;
    align-items: center;
    .title {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      margin: 0 auto;
      margin-top: 24px;
      .text {
        width: 600px;
        // height: 45px;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        // margin-left: 62px;
        text-align: center;
        margin: 0 auto;
      }
      .btn-box {
        width: 600px;
        text-align: center;
      }
      .abtn {
        width: 214px;
        height: 42px;
        background: linear-gradient(96deg, #ed1b23 0%, #f15f64 100%);
        box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
        border-radius: 8px 8px 8px 8px;
        color: #ffffff;
        margin-top: 37px;
        margin-bottom: 32px;
        // margin-left: 193px;
      }
    }

    .btnad {
      width: 112px;
      height: 40px;
      background: rgba(246, 247, 249, 1);
      border: 1px solid #ecedef;
      opacity: 1;
      border-radius: 20px;
    }
    .btnsad {
      color: #333;
      width: 112px;
      height: 40px;
      background: rgba(255, 195, 44, 1);
      opacity: 1;
      border-radius: 20px;
    }
  }
}
</style>
